namespace("oga.binding");

oga.binding.CurrencyBinding = {
	update : function(element, valueAccessor) {
		var valueUnwrapped = ko.unwrap(valueAccessor());
		var $element = $(element);
		
		var currency = "MT ";
			
		if ((valueUnwrapped % 1) == 0) {
			$element.text(currency + oga.formatNumber(valueUnwrapped));
		} else {
			$element.text(currency + oga.formatNumber(valueUnwrapped));
		}		
	}
};
